import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../layout';
import Header from '../header';
// import PostCard from '../post-card';
import Sidebar from '../sidebar';

const TechPosts = () => {
  return (
    <Layout>
      <Container>
        <Header title="Technique Posts" />
        <p>We are still working on it!</p>
        <Row>
          <Col xs={{ order: 2, span: 12 }} md={{ order: 1, span: 8 }} lg={{ order: 1, span: 9 }}>
            {/* <Row>
              <PostCard lg={6} />
              <PostCard lg={6} />
            </Row> */}
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 2, span: 4 }}
            lg={{ order: 2, span: 3 }}
            style={{ marginTop: '15px' }}
          >
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TechPosts;
